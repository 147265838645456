
import { createTheme } from '@mui/material';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Layout } from './Components/Layout/Layout';
import Home from './Components/Home/Home';
import About from './Components/About/About';
import Wellness from './Components/UseCases/Wellness';
import Artist from './Components/UseCases/Artist';
import Homemaker from './Components/UseCases/Homemaker';
import Brand from './Components/UseCases/Brand';
import MembersList from './Components/Features/Members';
import Payments from './Components/Features//Payments';
import Dashboard from './Components/Features/Dashboard';
import Communitylist from './Components/Features/Communitylist';
import Plans from './Components/Features/Plans';
import Post from './Components/Features/Post';
import Contact from './Components/Contact/Contact';
import Pricing from './Components/Pricing/Pricing';
import Business from './Components/UseCases/Business';
import { PrivacyPolicy } from './Components/Policy/PrivacyPolicy';
import { TermsAndCondition } from './Components/Policy/TermsandConditions';



const App = () => {

  const theme = createTheme({
    typography: {
      fontFamily: [
        'Montserrat',
      ].join(','),
    },
  });



  return (
    <>

      <ThemeProvider theme={theme}>
        <Router>
          <Routes>
            <Route path="/" element={<Navigate to="/home" replace={true} />} />
            <Route path="/" element={<Layout />}>
              <Route path="/home" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/pricing" element={<Pricing />} />
              <Route path="/contacts" element={<Contact />} />
              <Route path="/features" element={<MembersList />} />
              <Route path="/usecases" element={<Brand />} />
              <Route path="/business" element={<Business />} />
              <Route path="/wellness" element={<Wellness />} />
              <Route path="/lifestyle" element={<Artist />} />
              <Route path="/entrepreneur" element={<Homemaker />} />
              <Route path="/brand" element={<Brand />} />
              <Route path="/members" element={<MembersList />} />
              <Route path="/payments" element={<Payments />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/communities" element={<Communitylist />} />
              <Route path="/subscription" element={<Plans />} />
              <Route path="/post" element={<Post />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/terms-of-uses" element={<TermsAndCondition />} />
            </Route>
          </Routes>
        </Router>
      </ThemeProvider>

    </>
  );
}

export default App;
