import * as React from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { Button, Divider, Stack } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

const menuItems = [
  { text: "Home", link: "/home" },
  { text: "About", link: "/about" },
  { text: "Features", link: "/features" },
  { text: "Usecases", link: "/features" },
  { text: "Pricing", link: "/pricing" },
  { text: "Contacts", link: "/contacts" },
  { text: "FAQ", link: "/faq-answers" },
  // Add links for other menu items
];

type Anchor = "top" | "left" | "bottom" | "right";

export default function MobileSideBar() {
  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const list = (anchor: Anchor) => (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 250,
        p: 2,
      }}
      role="presentation"
    >
      <Stack
        display={"flex"}
        direction={"row"}
        justifyContent={"space-between"}
        sx={{ alignItems: "center" }}
      >
        <Link to="/home  " style={{ textDecoration: "none" }}>
          <Box
            component={"img"}
            src={require("../../../Assets/Images/Communn-new-logo.png")}
            alt=""
            sx={{ width: "60%" }}
          />
        </Link>
        <IconButton
          onClick={toggleDrawer(anchor, false)}
          sx={{ border: "1px solid #515151 " }}
        >
          <ClearIcon sx={{ color: "#515151" }} />
        </IconButton>
      </Stack>
      <List>
        {menuItems.map((item, index) => (
          <React.Fragment key={item.text}>
            <ListItem disablePadding sx={{ fontSize: "20px" }}>
              <ListItemButton
                component={Link}
                to={item.link}
                onClick={toggleDrawer(anchor, false)}
              >
                <ListItemText
                  primary={item.text}
                  sx={{
                    "& .css-10hburv-MuiTypography-root": {
                      fontSize: "14px",
                      color: "#515151",
                      fontFamily: "Montserrat",
                    },
                  }}
                />
              </ListItemButton>
            </ListItem>
            <Divider />
          </React.Fragment>
        ))}
      </List>
      <Stack
        display={"flex"}
        direction={"row"}
        justifyContent={"space-between"}
        sx={{ mt: 15 }}
        alignItems={"center"}
      >
        <Link to="/explore-communities  " style={{ textDecoration: "none" }}>
          <Button
            variant="contained"
            component={Link}
            to="https://admin.onecommunn.com"
            target="_blank"
            sx={{
              fontFamily: "Montserrat",
              textTransform: "capitalize",
              backgroundColor: "#2952A2",
              borderRadius: "30px",

              "&:hover": {
                backgroundColor: "#2952A2",
                cursor: "pointer",
                border: "none",
              },
            }}
          >
            Get Started for Free
          </Button>
        </Link>
      </Stack>
    </Box>
  );

  return (
    <div>
      {(["left"] as const).map((anchor) => (
        <React.Fragment key={anchor}>
          <IconButton
            onClick={toggleDrawer(anchor, true)}
            sx={{
              backgroundColor: "white",
              ml: 9,
              borderRadius: "30px",
              border: "1px solid #515151 ",
            }}
          >
            <MenuIcon sx={{ color: "#515151" }} />
          </IconButton>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
