import { Box, Grid, Paper, Stack, Typography } from "@mui/material";
import Aboutusbg from "../../Assets/Images/About/Aboutus.webp";
import Aboutusbg1 from "../../Assets/Images/About/Aboutsbg.webp";
import Aboutus2 from "../../Assets/Images/About/Aboutbg2.webp";
import { Helmet } from "react-helmet";
import { AboutContent, AboutHeading, FirstBox } from "./About.styles";

const About = () => {
  return (
    <>
      <Helmet>
        <title>About us - One Communn - Build & Manage your community</title>
        <meta
          name="description"
          content="Automate Community Management with AI-Powered
          Subscription effortlessly
          Our AI-powered platform enhances community management with tools for user data management, interaction,communication, subscription and payment processing, content management, and advanced analytics"
        />
        <meta name="format-detection" content="telephone=no" />
        <meta
          name="author"
          content="Automate Community Management with AI-Powered
          Subscription effortlessly
          Our AI-powered platform enhances community management with tools for user data management, interaction,communication, subscription and payment processing, content management, and advanced analytics"
        />
        <meta
          name="keywords"
          content="Automate Community Management with AI-Powered
          Subscription effortlessly
          Our AI-powered platform enhances community management with tools for user data management, interaction,communication, subscription and payment processing, content management, and advanced analytics"
        />
      </Helmet>
      <Box
        sx={FirstBox}
      >
        <Stack>
          <Grid
            container
            justifyContent={"center"}
            alignContent={"center"}
            alignItems={"center"}
            display={"flex"}
          >
            <Grid item xs={12} md={4.5} sx={{ mt: { xs: 20, md: 0 } }}>
              <Box
                component={"img"}
                src={require("../../Assets/Images/About/Aboutus1.png")}
                alt=""
                sx={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={11} md={7.5}>
              <Stack
                sx={{
                  ml: { xs: 5, md: 10 },
                  pt: 6,
                  pb: 6,
                  backgroundColor: "#ffffff",
                  borderRadius: "15px 0px 0px 15px",
                }}
              >
                <Paper sx={{ p: 5, boxShadow: "none", borderRadius: "10px" }}>
                  <Typography
                    sx={AboutHeading}
                  >
                    About Us
                  </Typography>
                  <br></br>
                  <Typography
                    sx={{
                      fontFamily: "Montserrat",
                      color: "#1A2D4C",
                      textAlign: "left",
                      fontSize: { xs: "14px", md: "16px" },
                      lineHeight: "30px",
                      mr: { xs: 0, md: 20 },
                    }}
                  >
                    Welcome to Communn, where communities flourish, connections
                    thrive, and change becomes possible. We are More than a
                    platform; a community revolution uniting strength and
                    genuine connections. Simplifying management for full
                    engagement, we empower you for earnings, identity creation,
                    and branding excellence. Communn is the catalyst for
                    transforming communities and amplifying connections.
                  </Typography>
                </Paper>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </Box>

      <Box
        sx={{
          backgroundImage: `url(${Aboutusbg1})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "fit-content",
          display: "flex",
          alignContent: "center",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Stack>
          <Grid
            container
            justifyContent={"center"}
            alignContent={"center"}
            alignItems={"center"}
            display={"flex"}
          >
            <Grid item xs={11} md={10}>
              <Stack
                sx={{
                  pt: 6,
                  pb: 6,
                  backgroundColor: "#ffffff",
                  borderRadius: "0px 100px 0px 0px",
                }}
              >
                <Paper
                  sx={{
                    p: 5,
                    boxShadow: "none",
                    borderRadius: "0px 100px 0px 0px",
                    m: { xs: 0, md: 15 },
                  }}
                >
                  <Typography
                    sx={AboutHeading}
                  >
                    Our Story
                  </Typography>
                  <br></br>
                  <Typography
                    sx={AboutContent}
                  >
                    At Communn, we're not just a tech startup – we're a team of
                    young innovators on a mission to revolutionize community
                    management since January 2022. Our shared vision is to
                    simplify community engagement and reshape how businesses
                    market themselves.
                  </Typography>
                  <br></br>
                  <br></br>
                  <Typography
                    sx={AboutContent}
                  >
                    Communn is more than a platform; it's a way of life. We've
                    seen the transformative power of tight-knit communities
                    within our team, fostering productivity and meaningful
                    relationships. This ethos extends to our product, empowering
                    diverse groups to create their communities with features
                    like subscriptions and recurring revenue.
                  </Typography>
                  <br></br>
                  <br></br>
                  <Typography
                    sx={{
                      fontFamily: "Montserrat",
                      color: "#1A2D4C",
                      textAlign: "justify",
                      fontSize: { xs: "14px", md: "16px" },
                      lineHeight: "30px",
                    }}
                  >
                    Join us in making the world a better place, one community at
                    a time.
                  </Typography>
                  <br></br>
                  <br></br>
                </Paper>
              </Stack>
            </Grid>

            <Grid item xs={11} md={2}></Grid>
          </Grid>
        </Stack>
      </Box>

      <Box
        sx={{
          backgroundColor: "#ffffff",
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "flex",
          alignContent: "center",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box component={"img"} src={Aboutus2} alt="" sx={{ width: "75%" }} />
      </Box>
      <Stack
        sx={{
          ml: { xs: 5, md: 20 },
          mr: { xs: 5, md: 20 },
          pt: { xs: 2, md: 5 },
        }}
      >
        <Typography
          sx={AboutHeading}
        >
          Our Vision
        </Typography>
        <br></br>
        
        <Typography
          sx={AboutContent}
        >
          We envision a world where every connection adds value, every transaction yields happy money, and every individual finds fulfillment and prosperity through meaningful contributions.
        </Typography>
        <br></br>
        <br></br>
        <Typography
          sx={AboutHeading}
        >
          Our Mission
        </Typography>
        <br></br>
        <Typography
          sx={AboutContent}
        >
          To revolutionize human interaction, fostering productivity and meaningful relationships for a better world.
        </Typography>
      </Stack>

      <Box
        sx={{
          // backgroundImage: `url(${Aboutusbg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "fit-content",
          display: "flex",
          alignContent: "center",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Stack>
          <Grid
            container
            justifyContent={"center"}
            alignContent={"center"}
            alignItems={"center"}
            display={"flex"}
          >
            <Grid item xs={12} md={4}>
              <Box
                component={"img"}
                src={require("../../Assets/Images/About/Aboutus4.png")}
                alt=""
                sx={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <Stack
                sx={{
                  ml: { xs: 2, md: 5 },
                  pt: 6,
                  pb: 6,
                  backgroundColor: "#E6E8EC",
                  borderRadius: "50px 0px 0px 50px",
                }}
              >
                <Paper
                  sx={{ p: 5, boxShadow: "none", backgroundColor: "#E6E8EC" }}
                >
                  <Typography
                    sx={{
                      color: "#2A53A2",
                      fontSize: { xs: "20px", md: "30px" },
                      fontFamily: "Montserrat",
                      fontWeight: "bold",
                      textAlign: "right",
                      mr: { xs: 2, md: 10 },
                    }}
                  >
                    What Sets Us Apart
                  </Typography>
                  <br></br>
                  <Typography
                    sx={{
                      textAlign: "justify",
                      fontFamily: "Montserrat",
                      color: "#1A2D4C",
                      fontSize: { xs: "14px", md: "16px" },
                      lineHeight: "30px",
                      ml: { xs: 0, md: 15 },
                      mr: { xs: 2, md: 10 },
                    }}
                  >
                    Communn is a versatile platform that adapts to your unique
                    needs. Whether you're an entrepreneur, an educator, an
                    artist, or simply passionate about building communities,
                    Communn is your toolkit for expanding horizons, nurturing
                    relationships, and exploring new frontiers. What sets us
                    apart is our commitment to providing everyone, regardless of
                    their background or expertise, with the power to tap into
                    recurring revenue streams. With our platform, anyone can
                    harness the potential of a secure and vibrant community to
                    generate steady income. We believe that the ability to
                    create, engage, and profit from your community should be
                    within reach for all, and that's exactly what Communn
                    delivers.
                  </Typography>
                </Paper>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </Box>
    </>
  );
};

export default About;
