import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import MobileSideBar from "./MobileNav/MobileSidebar";

const pages = ["Home", "About", "Features", "UseCases", "Pricing", "Contacts"];

const subPages: { [key: string]: string[] } = {
  UseCases: ["Brand", "Business", "Wellness", "Entrepreneur", "Lifestyle"],
  Features: ["Members", "Payments", "Dashboard", "Communities", "Subscription"],
};

const Header = () => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [subNavOpen, setSubNavOpen] = React.useState<string | null>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleOpenSubNav = (page: string) => {
    setSubNavOpen(page);
  };

  const handleCloseSubNav = () => {
    setSubNavOpen(null);
  };

  return (
    <AppBar
      position="sticky"
      sx={{
        backgroundColor: "#ffffff",
        boxShadow: "none",
        borderBottom: "1px solid #e0e0e0",
        p: 0.5,
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box
            component={"img"}
            src={require("../../Assets/Images/Logo.png")}
            alt=""
            sx={{
              width: "15%",
              display: { xs: "none", sm: "none", md: "block" },
            }}
          />
          <Box
            component={"img"}
            src={require("../../Assets/Images/Logo.png")}
            alt=""
            sx={{
              width: "50%",
              display: { xs: "block", sm: "block", md: "none" },
            }}
          />

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "flex", md: "none" },
              justifyContent: "flex-end",
            }}
          >
            <MobileSideBar />
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              justifyContent: "flex-end",
              mr: 3,
            }}
          >
            {pages.map((page) => (
              <Box key={page} sx={{ position: "relative", marginLeft: "20px" }}>
                <Button
                  component={Link}
                  to={`/${page.toLowerCase()}`} // Link to the page route
                  aria-controls={`menu-${page}`}
                  aria-haspopup="true"
                  onMouseEnter={() => handleOpenSubNav(page)}
                  onMouseLeave={handleCloseSubNav}
                  sx={{
                    color: "#3C3C3C",
                    fontFamily: "Montserrat",
                    fontWeight: 600,
                    "&:hover": {
                      color: "#2952A2",
                    },
                  }}
                >
                  {page}
                </Button>
                {subPages[page] && subNavOpen === page && (
                  <Box
                    sx={{
                      position: "absolute",
                      top: "100%",
                      left: 1,
                      minWidth: "200px",
                      backgroundColor: "#ffffff",
                      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                      zIndex: 1,
                      textAlign: "left",
                      borderRadius: "8px",
                      padding: "10px 0",
                    }}
                    onMouseEnter={() => handleOpenSubNav(page)}
                    onMouseLeave={handleCloseSubNav}
                  >
                    {subPages[page].map((subPage) => (
                      <Button
                        key={subPage}
                        component={Link}
                        to={`/${subPage.toLowerCase()}`}
                        onClick={handleCloseNavMenu}
                        sx={{
                          color: "#3C3C3C",
                          fontFamily: "Montserrat",
                          fontSize: "15px",
                          textTransform: "capitalize",
                          display: "block",

                          width: "100%",
                          padding: "8px",
                          "&:hover": {
                            backgroundColor: "#f5f5f5",
                          },
                        }}
                      >
                        {subPage}
                      </Button>
                    ))}
                  </Box>
                )}
              </Box>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Button
              variant="contained"
              component={Link}
              to="https://admin.onecommunn.com"
              target="_blank"
              sx={{
                fontFamily: "Montserrat",
                textTransform: "capitalize",
                backgroundColor: "#2952A2",
                borderRadius: "30px",
                display: { xs: "none", sm: "none", md: "block" },
                "&:hover": {
                  backgroundColor: "#2952A2",
                  cursor: "pointer",
                  border: "none",
                },
              }}
            >
              Get Started for Free
            </Button>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
