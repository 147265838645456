import { Button, Card, CardMedia, Grid, Paper, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import background from "../../Assets/Images/features/Bg.webp";
import feature from "../../Assets/Images/features/feature3/1.webp";
import { FeatureButton } from "./Features.styles";



const features = [
  {
    title: "Admin Convenience",
    description:
      "Easily manage and access your communities from a centralized list. Edit profiles, create new communities, and ensure accurate representation.",
    image: require("../../Assets/Images/features/feature1/2.png"),
  },
  {
    title: "Instant Community landing page",
    description:
      "Boost community visibility with custom branding and a dedicated website at onecommunn.com/communityname, offering free marketing to all.",
    image: require("../../Assets/Images/features/feature1/3.png"),
  },
  {
    title: "Create new communities",
    description:
      "Unleash your creativity with unlimited community creation. Communn's platform empowers you to curate spaces that match your vision.",
    image: require("../../Assets/Images/features/feature1/2.png"),
  },
];



const featureData = [
  {
    title: "Organized Command Center",
    description:
      "Seamlessly manage all your communities from one spot. This unified dashboard brings ease to your multitasking, letting you efficiently navigate through your various community domains.",
    image: require("../../Assets/Images/features/feature3/2.webp"),
  },
  {
    title: "Vibrant Community Profiles",
    description:
      "Breathe life into your community's identity. Craft captivating profiles by uploading attention-grabbing logos, banners, and comprehensive descriptions. Share your community's essence, aspirations, and services, painting a vivid picture for your members.",
    image: require("../../Assets/Images/features/feature3/3.webp"),
  },
  {
    title: "Unique Community Websites",
    description:
      "Every community deserves a spotlight. With Communn, each community receives its dedicated virtual haven at onecommunn.com/communityname. An instant web address to flaunt your community's distinctive identity, engage members, and fuel curiosity.",
    image: require("../../Assets/Images/features/feature3/4.webp"),
  },
  {
    title: "Amplified Engagement",
    description:
      "Empower your members with insights. As you meticulously enhance community details, you create an engaging platform. This increased engagement sparks discussions, drives participation, and fosters a sense of belonging.",
    image: require("../../Assets/Images/features/feature3/5.webp"),
  },
  {
    title: "Effortless Updates",
    description:
      "Keep your community's details fresh and relevant. Effortlessly edit, refine, and improve your community's online presence whenever needed, ensuring your members always have the latest information.",
    image: require("../../Assets/Images/features/feature3/6.webp"),
  },
  {
    title: "Personal Touch",
    description:
      "Beyond the digital realm, your community's online face becomes a tangible representation. Your community members can visit and explore this personalized web space, fostering a stronger connection.",
    image: require("../../Assets/Images/features/feature3/6.webp"),
  },

];



const Communitylist = () => {
  return (
    <>


      <Box
        sx={{
          backgroundImage: `url(${background})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "center",
          overflow: "hidden",
          height: { xs: "75vh", md: "100vh" },
        }}
      >
        {" "}
        <Stack
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            color="#2952A2"
            fontSize={"36px"}
            fontWeight={700}
            sx={{
              fontFamily: "Montserrat", mt: 20,
              fontSize: { xs: "30px", md: "36px" },
              textAlign: { xs: "center", md: "center" },
            }}
          >
            Explore communities effortlessly through
          </Typography>
          <Typography
            color="#3B9B7F"
            fontWeight={700}
            sx={{
              fontFamily: "Montserrat",
              fontSize: { xs: "30px", md: "36px", },
            }}
          >
            the Community List
          </Typography>

          <Button
            variant="contained"
            sx={FeatureButton}>
            Get Started for free
          </Button>
        </Stack>
      </Box>
      <Card
        elevation={0}
        sx={{ backgroundColor: "transparent", height: "fit-content", mt: -50 }}
      >
        <CardMedia
          component="img"
          alt="Background Image"
          src={feature}
          sx={{
            height: "100%",
            objectFit: "cover",
            position: "relative",
            mt: { xs: 35, md: 0 },
          }}
        />
      </Card>



      <Stack mt={10}>
        <Typography
          sx={{
            color: "#2A53A2",
            fontSize: { xs: "25px", md: "30px" },
            fontFamily: "Montserrat",
            fontWeight: "bold",
            textAlign: "center",
            pb: 2,
          }}
        >
          Join us on a journey where community management becomes a breeze! 🚀
        </Typography>
      </Stack>




      {/* Features Start */}
      <Stack
        display={"flex"}
        flexDirection={"row"}
        alignContent={"center"}
        justifyContent={"center"}
        alignItems={"center"}
        sx={{ ml: 4, mr: 4, pt: { xs: 5, md: 10 }, pb: { xs: 5, md: 10 }, mb: 5, borderRadius: "20px" }}
      >

        <Grid
          container
          spacing={{ xs: 2, md: 8 }}
          display={"flex"}
          flexDirection={"row"}
          alignContent={"center"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          {features.map((feature, index) => (
            <Grid item xs={12} md={3.5} key={index}>
              <Card
                sx={{
                  pt: 7,
                  pb: 7,
                  pl: 1,
                  pr: 1,
                  borderRadius: "15px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  component={"img"}
                  src={feature.image}
                  alt=""
                  sx={{ width: "35%" }}
                />
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontSize: "17px",
                    fontWeight: "bold",
                    p: 1,
                    textAlign: "center",
                    color: "#2952A2",
                  }}
                >
                  {feature.title}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontSize: "14px",
                    color: "#000000",
                    p: 1,
                    textAlign: "center",
                  }}
                >
                  {feature.description}
                </Typography>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Stack>
      {/* Features End */}



      {/* Features Data Start */}
      {featureData.map((feature, index) => (
        <Box
          key={index}
          sx={{
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
            alignItems: "center",
            height: { xs: "fit-content", md: "100vh" },
          }}
        >
          <Stack>
            <Grid
              container
              justifyContent={"center"}
              alignContent={"center"}
              alignItems={"center"}
              display={"flex"}
              spacing={{ xs: 2, md: 10 }}
              sx={{
                flexDirection: {
                  xs: "column-reverse",
                  md: index % 2 === 0 ? "row" : "row-reverse",

                },
                
                paddingBottom: {
                  md: index % 2 === 0 ? "0px" : "100px",
                },


              }}
            >
              <Grid item xs={12} md={5.5} sx={{ m: { xs: 2, md: 0 }, animation: "fadeInUp 5s ease-in-out", }}>
                <Box
                  component={"img"}
                  src={feature.image}
                  alt=""
                  sx={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12} md={4.5}>
                <Stack
                  sx={{
                    ml: 2,
                    pt: { xs: 0, md: 6 },
                    pb: { xs: 0, md: 6 },
                    backgroundColor: "#ffffff",
                    borderRadius: "15px",
                  }}
                >
                  <Paper sx={{ p: 2, boxShadow: "none" }}>
                    <Typography
                      sx={{
                        color: "#2A53A2",
                        fontSize: { xs: "18px", md: "24px" },
                        fontFamily: "Montserrat",
                        fontWeight: "bold",
                        pb: 2,
                      }}
                    >
                      {feature.title}
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Montserrat",
                        color: "#1A2D4C",

                        fontSize: { xs: "14px", md: "16px" },
                        lineHeight: "30px",
                      }}
                    >
                      {feature.description}
                    </Typography>
                  </Paper>
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        </Box>
      ))}

      {/* Features Data Start */}
    </>
  );
};

export default Communitylist;
