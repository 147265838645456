import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import AddBoxIcon from "@mui/icons-material/AddBox";
import star from "../../Assets/Images/starFaq.png";
import FaqData from "../Faq/Faq.json";

const Faq = () => {
  const [expandedQuestionId, setExpandedQuestionId] = useState<number | null>(
    null
  );

  const handleToggleAnswer = (id: number) => {
    setExpandedQuestionId((prev) => (prev === id ? null : id));
  };

  const halfLength = Math.ceil(FaqData.length / 2);
  const leftSideFAQ = FaqData.slice(0, halfLength);
  const rightSideFAQ = FaqData.slice(halfLength);

  const calculateMaxHeight = (item: (typeof leftSideFAQ)[0]) => {
    const maxHeight = 200; // Set your desired maximum height
    return expandedQuestionId === item.id ? "auto" : `${maxHeight}px`;
  };

  const calculateTransform = (item: (typeof leftSideFAQ)[0]) => {
    const transformValue =
      expandedQuestionId === item.id ? "translateY(0)" : "translateY(-100%)";
    return {
      transform: transformValue,
      overflow: "hidden",
    };
  };

  return (
    <>
      <Box sx={{ marginBottom: "20px" }}>
        <Stack sx={{ left: 0, right: 0, textAlign: "center" }}>
          <img
            src={star}
            alt="star"
            width={"50px"}
            style={{ marginLeft: "auto", marginRight: "10%" }}
          />
        </Stack>
        <Typography
          variant="h4"
          sx={{
            color: "#2952A2",
            padding: "20px",
            textAlign: "center",
            fontFamily: "Montserrat",
            fontWeight: "700",
            fontSize: { xl: "30px", lg: "30px", xs: "20px", sm: "20px" }
          }}
        >
          Frequently Asked Questions
        </Typography>
      </Box>
      <Box  sx={{backgroundColor:'#F6F6F6', ml:{xs:2,md:10},mr:{xs:2,md:10}, pb:10,pt:10, borderRadius:'10px', }}>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={10} lg={5} xl={5} md={5}>
          {leftSideFAQ.map((item) => (
            <Accordion
              key={item.id}
              expanded={expandedQuestionId === item.id}
              onChange={() => handleToggleAnswer(item.id)}
              sx={{
                width: "100%",
                marginBottom: "15px",
                minHeight: "100px",
                boxShadow: "0px 5px 16px 0px rgba(8, 15, 52, 0.06)",
                borderRadius: "14px",
                padding: "5px",
                display: 'flex', // Center vertically
                justifyContent: "center",
                alignItems: "center",
                flexDirection: 'column', // Stack children vertically
                
              }}
            >
              <AccordionSummary
                expandIcon={
                  expandedQuestionId === item.id ? (
                    <IndeterminateCheckBoxIcon sx={{ color: "#2A53A2" }} />
                  ) : (
                    <AddBoxIcon sx={{ color: "#2952A2" }} />
                  )
                }
                sx={{
                  justifyContent: 'space-between', // Space between the icon and text
                  width: '100%', // Take the full width
                }}
              >
                <Typography
                  fontFamily="Montserrat"
                  sx={{
                    fontSize: { lg: "17px", xl: "17px", xs: "14px", sm: "14px", fontWeight: "600" },

                  }}
                >
                  {item.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={calculateTransform(item)}>
                <Typography
                  fontFamily="Montserrat"
                  sx={{
                    fontSize: { lg: "14px", xl: "14px", xs: "14px", sm: "14px" },
                    //  textAlign: 'center', // Center horizontally
                    marginTop: 'auto', // Push to the middle vertically
                  }}
                >
                  {item.answer}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Grid>
       

        <Grid item xs={10} lg={5} xl={5} md={5}>
          {rightSideFAQ.map((item) => (
            <Accordion
              key={item.id}
              expanded={expandedQuestionId === item.id}
              onChange={() => handleToggleAnswer(item.id)}
              sx={{
                width: "100%",
                marginBottom: "15px",
                minHeight: "100px",
                boxShadow: "0px 5px 16px 0px rgba(8, 15, 52, 0.06)",
                borderRadius: "14px",
                padding: "5px",
                display: 'flex', // Center vertically
                justifyContent: "center",
                alignItems: "center",
                flexDirection: 'column', // Stack children vertically
                "& .css-1mdk3jr-MuiPaper-root-MuiAccordion-root:before" :{
                  position:'absolute',
                  backgroundColor:'red',
                }
              }}
            >
              <AccordionSummary
                expandIcon={
                  expandedQuestionId === item.id ? (
                    <IndeterminateCheckBoxIcon sx={{ color: "#2A53A2" }} />
                  ) : (
                    <AddBoxIcon sx={{ color: "#2952A2" }} />
                  )
                }
                sx={{
                  justifyContent: 'space-between', // Space between the icon and text
                  width: '100%', // Take the full width
                }}
              >
                <Typography
                  fontFamily="Montserrat"
                  sx={{
                    fontSize: { lg: "17px", xl: "17px", xs: "14px", sm: "14px", fontWeight: "600" },
                   
                  }}
                >
                  {item.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={calculateTransform(item)}>
                <Typography
                  fontFamily="Montserrat"
                  sx={{
                    fontSize: { lg: "14px", xl: "14px", xs: "14px", sm: "14px" },
                    marginTop: 'auto', // Push to the middle vertically
                  }}
                >
                  {item.answer}
                </Typography>
              </AccordionDetails>
            </Accordion>


          ))}
        </Grid>

      </Grid>
      </Box>
    </>
  );
};

export default Faq;