export const Title = {
  color: "#000000",
  fontFamily: "Montserrat",
  fontWeight: "bold",
  textTransform: "capitalize",
  pt: 5,
  pb: 2,
  fontSize: { xs: "13px", md: "18px" },
};

export const Field = {
  fontFamily: "Montserrat",
  fontSize: { xs: "13px", md: "18px" },
};


